export const API_URL_LOCAL = "http://localhost:1337/api";
export const API_URL_PROD = "/api";
export const API_URL =
  process.env.NODE_ENV === "production" ? API_URL_PROD : API_URL_LOCAL;

export const GOOGLE_SCRIPT_BUILD_ID =
  "AKfycbxg1L6nHHmM7HD-cYZIXUuUZS8Zg2YllRKDm84Qcd8Z10xQwnbraE60CHIYSFyF3KeG"; // v31 - new file name suffix
// "AKfycbwNTBTr-a5viTwyywuG5pc2pW55ISus0astExS6WxLhzBMn-pZJCu-N6VRJOP4Y3AYv"; // v30 - keep unchanged placeholders
// "AKfycbwGsy9MG78VVEhcpwwCIJ10FvLw72jLZCvn7ypQ_4inY6ox5jZNQT8zv7zsvbe6gUwW"; // v29 - generate service template
// "AKfycbyvjJDCpuwlNYPD64W8uXTpdC9jkXbCN5It_OYwffpcHNieHU5FCupUTVHg6Id8WkVC"; // v28 - list of brands models and files with parentId
// "AKfycbyugdO0qR8R3P3muV8r7tdQ2xFGa3BbZ81DLyl4max7GFQihcjY3uWUlAI47Wohfqgc"; // v27 - list of brands models and files
// "AKfycbx6Wmy8el4Cc3RVbHTZgY8Y2JTROtOv--P6_bRKeKLS2eR6TicKf5Uy24NZV5ij2Gwu"; // v25 - remove doc ids from gs
// "AKfycbyGMwYnooHOQlOyhyaY_Gn3az0sC_suo7jtcVu_P-TqLzXTmJ3JxgLeYuB-WaygLhks"; // v24 - response improvement
// "AKfycbzNKMTPbrwslr2zCAhjrYO6LieOevzzAKcHFCj2WcpyFX9t93l2YSloOnDFY4pfgg"; // v23 - code cleanup
// "AKfycbwAr0L5HLDInYqOBOCave7IAfexMLH_tgQRX6WNUVzjZHMo5KIdgZiF4Y8PUs03cbPx"; // v22 - File generation works!
// "AKfycbzO4pW8Y-r2Hg4XFoTRSD7mz_74Y6QnPqwbzT9nqsyJpOaBMK_IVwWrPKjVAoVqMLwM"; //v21 - debug
// "AKfycbzDxZBXihwvAPG9VnOOgCWtwZVU7V5aKhw8jRrM-L3r5esYUzhmwbQoE6dbqSylKVFH"; //v20 - debug
// "AKfycby8hvAj2HSTgZF-mWbVFUutR0DfgJugHJZthRTUvqjwdLl7m8DNbkmffM6TtHzdX9c2"; //v19 - debug
// "AKfycbzz7D3RmP63xNMfNxOVzqH6rKwliJdfukXHSlfkFkBFQrMzi58Li6JyZ-s2y-4HJHQA"; //v18 - debug
// "AKfycbxCXzqLrlmdVfgiYu-JlnKDh-2w5yI5ZjGtYzW9a_JY91QAjo93xMEvgF40xM3wjRUI"; //v17 - debug
// "AKfycbw5fM0bopJ7_DTvbtUjOBI0d8vVfbIuPS6nCgTBRCxD--PBKmyaT7BBuudiJG3Xf0PE"; //v16 - debug
// "AKfycbxJH_8OypEdHxrfBpWLS8sNyD8GYLH7-l6mvhfRWcem3shpRzIL89E-Qz0qloi9Fapr"; //v15 - debug
// "AKfycbylBsYVAPDPxA8bKzZp8dikao-Ff8ZImOgEia8E_F7qT5_3Lu0_bnA9J2yZZZdQ1d1z"; //v14 - debug
// "AKfycbxyw6Ecja5xEE9UMYkmdTb_CW2yjgbScr5viVw5fs7SxuinZlwvWGg60HO6_A1wXhux"; //v13 - debug
// "AKfycbwX4um7jZ4dPZ6263ySC1s2S7ad5F5lHckArIowxe0VNsKALKRW59NCnxHoIqyFVUdW"; //v12 - debug
// "AKfycbzevcfgsaC2jMRqC36_pKa8gd2WCkJbo1HcYwz9NztNet6oeWG8RmlYk-i3mYibN5tb"; //v11 - debug
// "AKfycbyJlowbnWGztK_2WCbz1qy5NGG2PDeWbULvHvgJ3xIqfzDUO3H0UK9jqyo1YXg5LCEp"; //v10 - debug
// "AKfycbyABzMFNZniaGU59-2boa86r-FUsOT0LNySFFAqpygYvp-c-NHviwwuekUv08ujAtKx"; //v9 - debug
// "AKfycbw_H1Ha-K8KU04q-QL6YEDArRQxnWGWHlAoQ3fmYIyPT74U5afAKRQdlNniCJbK3CyG"; //v8 - debug
// "AKfycbxNsxpOot4IaLfYIdi9-x6xGbKEwBBn7pOVaEv2JlHiOs3iwyoPr_1rOlSntquMkks6"; //v7 - debug
// "AKfycbzpaqSmX9oVd-73jbK4fr5_iOmPT5u5eyt7DB7hx9lZZovp1H8s0ewRbGEWaYCS3VOa"; //v6 - debug
// "AKfycbx4kuzIeIo3n706O0FDhLF72fwG7dQOi5dmj9Sud55VyDhBax5304NTyjXq0X-N_Rye"; //v5 - debug
// "AKfycbwU912zD2K2-rxP_Vm-Mmb_tH2vtVktRyPvYOr8yOud6aRR7kGcS3fTB63Bfp72dyAX"; //v4 - debug
// "AKfycbxBF9URw2236wypdy7QKiR7NBq8bi9Z24fDrNXxohy7ahuvbP63TCr7Rx2YY75SX4hO"; //v3 - debug
// "AKfycbyu5AgQ9_6OsAKx3_uMXpE4M8xov971rksJzag4vxGqky-yNuxKvMDvMmoZzpMj5KN8"; //v2 - debug
// "AKfycbzQQjK-4ber7Tb97LSuSQ-7EWBOb3ea3C0rBN9oJxkylZzBKWE3mSDbJBGr2J5rghXL"; //v1 - debug
export const GOOGLE_SCRIPT_BUILD_URL = `https://script.google.com/macros/s/${GOOGLE_SCRIPT_BUILD_ID}/exec`;

export const GOOGLE_SCRIPT_DEV_ID =
  "AKfycbw1OA7ibI7c76__3xAjvw1PzQcwLOTIRh4w5lqo3lo";
export const GOOGLE_SCRIPT_DEV_URL = `https://script.google.com/macros/s/${GOOGLE_SCRIPT_DEV_ID}/dev`;

export const GOOGLE_SCRIPT_URL = GOOGLE_SCRIPT_BUILD_URL;
