import { useMemo, useState } from "react";

import { getBrandModels, getBrands } from "../api/api";
import useSignal from "./useSignal";
import { DriveFolder } from "../typings/models";
import useApiEffect from "./useApiEffect";

interface BrandModelsProps {
  brandId: string;
}
const useBrandModels = (props: BrandModelsProps) => {
  const { brandId } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState<DriveFolder[]>([]);
  const signal = useSignal();

  useApiEffect(async () => {
    setIsLoading(true);
    const response = await getBrandModels(brandId, { signal });
    if (signal.aborted) return;
    setIsLoading(false);
    if (response.status !== "success") {
      setItems([]);
      return;
    }
    setItems(response.data);
  }, [brandId]);

  return { items, isLoading };
};

export default useBrandModels;
