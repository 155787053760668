import React, { useEffect, useState } from "react";
import { DriveFile, DriveFolder } from "../typings/models";
import { Box, IconButton, ListItem, Stack, Typography } from "@mui/material";
import { mapStyles } from "../libs/styles";
import SortableList from "./SortableList";
import DeleteIcon from "@mui/icons-material/Delete";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ConstructionIcon from "@mui/icons-material/Construction";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import useMount from "../hooks/useMount";
import { getBrandModelFiles, getBrandModels, getBrands } from "../api/api";
import useSignal from "../hooks/useSignal";
import {
  CheckCircleSharp,
  KeyboardDoubleArrowDownSharp,
  KeyboardDoubleArrowRightSharp,
  RadioButtonUncheckedSharp,
} from "@mui/icons-material";
import useBreakpoints from "../hooks/useBreakpoints";

interface CustomFilesSearchProps {
  genericFiles?: DriveFile[];
  selectedFiles?: DriveFile[];
  onSelectedFilesChange?: (files: DriveFile[]) => void;
  onCreateServiceScheduleTemplate?: (folder: DriveFolder) => void;
}
export const DriveFilesSelector: React.FC<CustomFilesSearchProps> = (props) => {
  const {
    genericFiles = [],
    selectedFiles = [],
    onSelectedFilesChange = () => {},
    onCreateServiceScheduleTemplate,
  } = props;

  const [openItems, setOpenItems] = useState<Map<string, boolean>>(new Map());
  const [brands, setBrands] = useState<DriveFolder[]>([]);
  const [brandsIsLoading, setBrandsIsLoading] = useState<boolean>(false);
  const [models, setModels] = useState<Map<string, DriveFolder[]>>(new Map());
  const [files, setFiles] = useState<Map<string, DriveFile[]>>(new Map());

  const { isMobile } = useBreakpoints();
  const signal = useSignal();

  useMount(async () => {
    setBrandsIsLoading(true);
    const res = await getBrands({ signal });
    if (signal.aborted) return;
    setBrandsIsLoading(false);
    setBrands(res.status === "success" ? res.data : []);
  });

  const appendBrandModels = async (brandId: string) => {
    // setModels(new Map([...models, [brandId, null]]));
    const res = await getBrandModels(brandId, { signal });
    if (signal.aborted) return;
    setModels(
      new Map([...models, [brandId, res.status === "success" ? res.data : []]]),
    );
  };

  const appendBrandModelFiles = async (brandId: string, modelId: string) => {
    // setFiles(new Map([...files, [modelId, null]]));
    const res = await getBrandModelFiles(brandId, modelId, { signal });
    if (signal.aborted) return;
    setFiles(
      new Map([...files, [modelId, res.status === "success" ? res.data : []]]),
    );
  };

  const handleItemExpansionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isExpanded: boolean,
  ) => {
    // console.log(event, itemId, isExpanded);
    setOpenItems(new Map([...openItems, [itemId, isExpanded]]));
    if (!isExpanded) return;
  };

  const toggleBrand = (brand: DriveFolder) => {
    if (models.has(brand.id)) return;
    appendBrandModels(brand.id);
  };

  const toggleBrandModel = (model: DriveFolder, brand: DriveFolder) => {
    // if (files.has(model.id)) return;
    if (openItems.get(model.id)) return; // don't fetch child items when parent is closed
    appendBrandModelFiles(brand.id, model.id);
  };

  const toggleBrandModelFile = (
    file: DriveFile,
    model?: DriveFolder,
    brand?: DriveFolder,
  ) => {
    if (selectedFiles.some((f) => f.id === file.id)) return;
    onSelectedFilesChange([...selectedFiles, file]);
  };

  const handleReorder = (values: any[], from: number, to: number) => {
    const list = [...values];
    list.splice(to, 0, list.splice(from, 1)[0]);
    onSelectedFilesChange(list);

    const remainingClones = document.querySelectorAll(".sortable-item-clone");
    if (remainingClones.length > 0) {
      console.warn(
        `Sortable clone elements don't disappear and should be removed manually`,
        remainingClones.length,
      );
      remainingClones.forEach((e) => e.remove());
    }
  };

  const handleDeleteItem = (values: any[], index: number) => {
    const list = [...values];
    list.splice(index, 1);
    onSelectedFilesChange(list);
  };

  return (
    <Box sx={styles.root}>
      <SimpleTreeView
        onItemExpansionToggle={handleItemExpansionToggle}
        sx={styles.treeView}
      >
        {genericFiles.length && (
          <TreeItem itemId="genericFiles" label="Generic" key="generic">
            {genericFiles.map((f, idx) => (
              <TreeItem
                key={f.id}
                itemId={f.id}
                label={
                  <>
                    {selectedFiles.some((sf) => sf.id === f.id) ? (
                      <CheckCircleSharp sx={styles.fileIcon} />
                    ) : (
                      <RadioButtonUncheckedSharp sx={styles.fileIcon} />
                    )}
                    {f.name}
                  </>
                }
                onClick={() => toggleBrandModelFile(f)}
              ></TreeItem>
            ))}
          </TreeItem>
        )}

        {brandsIsLoading ? (
          <TreeItem
            key={"brands-loading"}
            itemId={"brands-loading"}
            label={`Loading brands...`}
            disabled
          />
        ) : (
          brands.map((b: DriveFolder, idx: number) => (
            <TreeItem
              key={b.id}
              itemId={b.id}
              label={b.name}
              onClick={() => toggleBrand(b)}
            >
              {!models.has(b.id) ? (
                <TreeItem
                  key={b.id + "-loading"}
                  itemId={b.id + "-loading"}
                  label={`Loading ${b.name} models...`}
                  disabled
                />
              ) : !models.get(b.id)?.length ? (
                <TreeItem
                  key={b.id + "-no-items"}
                  itemId={b.id + "-no-items"}
                  label="No models"
                  disabled
                />
              ) : (
                models.get(b.id)?.map((m: DriveFolder) => (
                  <TreeItem
                    key={m.id}
                    itemId={m.id}
                    label={m.name}
                    onClick={() => toggleBrandModel(m, b)}
                  >
                    {!files.has(m.id) ? (
                      <TreeItem
                        key={m.id + "-loading"}
                        itemId={m.id + "-loading"}
                        label={`Loading ${m.name} files...`}
                        disabled
                      />
                    ) : !files.get(m.id)?.length ? (
                      <>
                        <TreeItem
                          key={m.id + "-no-items"}
                          itemId={m.id + "-no-items"}
                          label="No files"
                          disabled
                        />
                      </>
                    ) : (
                      files.get(m.id)?.map((f: DriveFile) => (
                        <TreeItem
                          key={f.id}
                          itemId={f.id}
                          label={
                            <>
                              {selectedFiles.some((sf) => sf.id === f.id) ? (
                                <CheckCircleSharp sx={styles.fileIcon} />
                              ) : (
                                <RadioButtonUncheckedSharp
                                  sx={styles.fileIcon}
                                />
                              )}
                              {f.name}
                            </>
                          }
                          onClick={() => toggleBrandModelFile(f, m, b)}
                        />
                      ))
                    )}

                    {!!onCreateServiceScheduleTemplate && (
                      <TreeItem
                        key={m.id + "-create-service-schedule"}
                        itemId={m.id + "-create-service-schedule"}
                        label={
                          <>
                            <ConstructionIcon sx={styles.fileIcon} /> Create
                            Service Schedule for {m.name}
                          </>
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onCreateServiceScheduleTemplate(m);
                        }}
                        sx={styles.createServiceSchedule}
                      />
                    )}
                  </TreeItem>
                ))
              )}
            </TreeItem>
          ))
        )}
      </SimpleTreeView>

      <Box sx={styles.arrowContainer}>
        {isMobile ? (
          <KeyboardDoubleArrowDownSharp />
        ) : (
          <KeyboardDoubleArrowRightSharp />
        )}
      </Box>

      <SortableList
        items={selectedFiles}
        axis="y"
        lockAxis="y"
        label={(option) => `${option.name}`}
        pressDelay={200}
        onSortEnd={(e) => {
          handleReorder(selectedFiles, e.oldIndex, e.newIndex);
        }}
        helperClass={"sortable-item-clone allow-pointer-events"}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        listItem={(option, idx) => (
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  handleDeleteItem(selectedFiles, idx);
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
            sx={styles.listItem}
          >
            <DragHandleIcon sx={styles.dragIcon} />
            <Stack sx={styles.listItemWrapper}>
              <Typography>
                {idx + 1}.&nbsp;{option.name}
              </Typography>
            </Stack>
          </ListItem>
        )}
        sx={styles.sortableList}
      />
    </Box>
  );
};

const styles = mapStyles({
  root: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    gap: 2,
  },

  treeView: {
    flexBasis: "50%",
    border: (t) => `1px solid ${t.palette.divider}`,
    borderRadius: 1,
    "& .MuiTreeItem-label": {
      display: "flex",
      alignItems: "center",
    },
  },
  fileIcon: {
    fontSize: 16,
    mr: 1,
  },
  arrowContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sortableList: {
    flexBasis: "50%",
    border: (t) => `1px solid ${t.palette.divider}`,
    borderRadius: 1,
  },

  listItem: {
    cursor: "grab",
    userSelect: "none",
    backgroundColor: "common.white",
  },
  listItemWrapper: {
    overflow: "hidden",
    wordBreak: "break-word",
  },
  dragIcon: {
    marginLeft: -1.5,
    marginRight: 1.5,
    opacity: 0.5,
  },
  createServiceSchedule: {
    "& .MuiTreeItem-content": {
      color: "info.dark",
    },
  },
});
