import React, { useState } from "react";
import { useFormik } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { mapStyles } from "../libs/styles";
import {
  ContractFormModel,
  DriveFile,
  DriveFolder,
  GeneratedDocument,
} from "../typings/models";
import useMount from "../hooks/useMount";
import { errorUtil } from "../libs/formikError";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  BrandsEnum,
  ClientTypeEnum,
  DocumentTypeEnum,
  VehicleTypeEnum,
  VendorPresetEnum,
} from "../typings/enums";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { generateDocuments } from "../api/api";
import useQueryParams from "../hooks/useQueryParams";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import AutoFixHighSharpIcon from "@mui/icons-material/AutoFixHighSharp";
import useSignal from "../hooks/useSignal";
import {
  DATE_FORMAT,
  FORM_DEFAULT_VALUES,
  FORM_TEST_VALUES,
} from "../constants/formDefaultValues";
import { validationSchema } from "../constants/validationSchema";
import { dateTimeAdapter, outputDate } from "../libs/dateAdapters";
import { generateWords } from "numbers-to-words-romanian";
import { DriveFilesSelector } from "./DriveFilesSelector";
import {
  DRIVE_FILES_INDIVIDUAL,
  DRIVE_FILES_LEGAL_ENTITY,
  DRIVE_FILES_SERVICE_SCHEDULE,
  OTHER_DRIVE_FILES_GENERIC,
} from "../constants/driveFiles";
import ServiceScheduleDialog from "./ServiceScheduleDialog";
import useBrands from "../hooks/useBrands";
import useBrandModels from "../hooks/useBrandModels";

interface ContractFormProps {}

const ContractForm: React.FC<ContractFormProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<DriveFile[]>([]);
  const [generatedDocument, setGeneratedDocument] =
    useState<Nullable<GeneratedDocument>>(null);
  const [modelToCreateServiceSchedule, setModelToCreateServiceSchedule] =
    useState<Nullable<DriveFolder>>(null);

  const [vendorPreset, setVendorPreset] = useState<VendorPresetEnum>(
    VendorPresetEnum.Other,
  );

  const signal = useSignal();

  const onSubmit = async (data: ContractFormModel) => {
    setLoading(true);

    // let documents: DocumentTypeEnum[] = [];
    //
    // if (data.clientType === ClientTypeEnum.Individual) {
    //   documents = [
    //     ...documents,
    //     DocumentTypeEnum.ContractMotopartsFizica,
    //     DocumentTypeEnum.ActPremirePredareFizica,
    //   ];
    // } else {
    //   documents = [
    //     ...documents,
    //     DocumentTypeEnum.ContractMotopartsJuridica,
    //     DocumentTypeEnum.ActPremirePredareJuridica,
    //   ];
    // }
    //
    // switch (data.type) {
    //   case VehicleTypeEnum.ATV:
    //     documents = [
    //       ...documents,
    //       DocumentTypeEnum.FisaTehnicaAtv,
    //       DocumentTypeEnum.TalonDeGarantieAtv,
    //       DocumentTypeEnum.ConditiiSiInstructiuniAtv,
    //     ];
    //     break;
    // }
    //
    // switch (data.brand) {
    //   case BrandsEnum.TGB:
    //     documents = [...documents, DocumentTypeEnum.ServicePlanTgb];
    //     break;
    // }

    let documents: UUID[] = selectedFiles.map((i) => i.id);

    if (data === FORM_TEST_VALUES) {
      documents = [
        ...new Set([
          ...selectedFiles,
          ...DRIVE_FILES_INDIVIDUAL,
          ...DRIVE_FILES_LEGAL_ENTITY,
          ...OTHER_DRIVE_FILES_GENERIC,
        ]),
      ].map((i) => i.id);
      console.log("All documents will be generate for test data");
    }

    const res = await generateDocuments(data, documents, { signal });
    if (signal.aborted) return;
    setLoading(false);
    if (res.status !== "success") {
      alert(res.message ?? "Something went wrong");
      return;
    }
    setGeneratedDocument(res.data);
  };

  const {
    values,
    handleSubmit,
    errors,
    touched,
    isValid,
    setValues,
    setFieldValue,
    setFieldTouched,
    validateForm,
  } = useFormik({
    initialValues: FORM_DEFAULT_VALUES,
    validationSchema,
    onSubmit,
  });

  const { items: brands, isLoading: brandsIsLoading } = useBrands();
  const { items: models, isLoading: modelsIsLoading } = useBrandModels({
    brandId: brands.find((i) => i.name === values.brand)?.id ?? "",
  });

  useMount(async () => {
    await validateForm();
  });

  const { withTest } = useQueryParams();

  const errorHandling = errorUtil(touched, errors);

  const handleChange = async (name: string, value: any, touch?: boolean) => {
    await setFieldValue(name, value);
    if (touch) {
      await setFieldTouched(name, true);
    }
    if (name === "date" && !values["contractNumber"]) {
      const contractDate = dateTimeAdapter(value)?.toUTC()?.toFormat("yyMMdd");
      const timeNow = DateTime.now().toFormat("HHmm");
      handleChange("contractNumber", contractDate + timeNow, true);
    }
    if (name === "clientType") {
    }
    if (name === "priceNumber") {
      handleChange("priceWord", generateWords(+value), true);
    }
  };

  const handleBlur = async (name: string, touch?: boolean) => {
    await setFieldTouched(name, touch);
  };

  const handleFillTestData = async () => {
    // const flud: ContractFormModel = Object.entries(FORM_DEFAULT_VALUES).reduce(
    //   (acc, item) => {
    //     const [key, value] = item;
    //     if (!value) {
    //       // @ts-ignore
    //       acc[key] = (Math.random() + 1).toString(36).substring(7);
    //       if (key === "email") {
    //         acc[key] += `@${acc[key]}.com`;
    //       }
    //     }
    //     return acc;
    //   },
    //   FORM_DEFAULT_VALUES,
    // );
    // await setValues(flud);
    await setValues(FORM_TEST_VALUES);
    await validateForm();
  };

  const handleVendorPresetChange = (vendor: VendorPresetEnum | string) => {
    if (vendor === VendorPresetEnum.Other) {
      handleChange("vendorFirstName", "", true);
      handleChange("vendorLastName", "", true);
    } else {
      const [vendorFirstName, vendorLastName] = vendor.split(" ");
      handleChange("vendorFirstName", vendorFirstName, true);
      handleChange("vendorLastName", vendorLastName, true);
    }
    setVendorPreset(vendor as VendorPresetEnum);
  };

  const handleCloseDialog = () => {
    setGeneratedDocument(null);
  };

  const handleServiceScheduleGenerated = (
    generatedFile?: GeneratedDocument,
  ) => {
    if (generatedFile) {
      setSelectedFiles((current) => [
        ...current,
        { name: generatedFile.name, id: generatedFile.docId },
      ]);
    }
    setModelToCreateServiceSchedule(null);
  };

  const genericClientFields = (
    <>
      <TextField
        label="Telefon"
        value={values.phone}
        onChange={(ev) => handleChange("phone", ev.target.value)}
        onBlur={() => handleBlur("phone", true)}
        error={errorHandling.isInvalid("phone")}
        required
        disabled={loading}
        sx={styles.input}
      />
      <TextField
        label="E-mail"
        value={values.email}
        onChange={(ev) => handleChange("email", ev.target.value)}
        onBlur={() => handleBlur("email", true)}
        error={errorHandling.isInvalid("email")}
        required
        disabled={loading}
        sx={styles.input}
      />
    </>
  );

  return (
    <>
      <Box sx={styles.form} component="form" onSubmit={handleSubmit}>
        <Stack gap={1}>
          <Stack component="fieldset" sx={styles.fieldset}>
            <Box component="legend">De baza</Box>
            <FormControl required error={errorHandling.isInvalid("clientType")}>
              <FormLabel>Tipul clientului</FormLabel>
              <RadioGroup
                row
                value={values.clientType}
                onChange={(ev) => handleChange("clientType", ev.target.value)}
                onBlur={() => handleBlur("clientType", true)}
              >
                {Object.values(ClientTypeEnum).map((clientType, idx) => (
                  <FormControlLabel
                    key={idx}
                    value={clientType}
                    control={<Radio />}
                    label={clientType}
                    disabled={loading}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <DatePicker
              label="Data"
              format={DATE_FORMAT}
              value={dateTimeAdapter(values.date)}
              onChange={(newValue: DateTime | null) =>
                handleChange(
                  "date",
                  newValue ? outputDate(newValue) : null,
                  true,
                )
              }
              slotProps={{
                textField: {
                  error: errorHandling.isInvalid("date"),
                  onBlur: () => handleBlur("date", true),
                  required: true,
                },
              }}
              disabled={loading}
              sx={styles.input}
            />
            <TextField
              label="Numărul contractului"
              value={values.contractNumber}
              onChange={(ev) => handleChange("contractNumber", ev.target.value)}
              onBlur={() => handleBlur("contractNumber", true)}
              error={errorHandling.isInvalid("contractNumber")}
              required
              disabled={loading}
              sx={styles.input}
            />
          </Stack>

          <Stack component="fieldset" sx={styles.fieldset}>
            <Box component="legend">Vânzător</Box>

            <FormControl required error={errorHandling.isInvalid("clientType")}>
              <RadioGroup
                row
                value={vendorPreset}
                onChange={(ev) => handleVendorPresetChange(ev.target.value)}
              >
                {Object.values(VendorPresetEnum).map((vendor, idx) => (
                  <FormControlLabel
                    key={idx}
                    value={vendor}
                    control={<Radio />}
                    label={vendor}
                    disabled={loading}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <TextField
              label="Prenume vânzătorului"
              value={values.vendorFirstName}
              onChange={(ev) =>
                handleChange("vendorFirstName", ev.target.value)
              }
              onBlur={() => handleBlur("vendorFirstName", true)}
              error={errorHandling.isInvalid("vendorFirstName")}
              required
              disabled={loading || vendorPreset !== VendorPresetEnum.Other}
              sx={styles.input}
            />
            <TextField
              label="Numele vânzătorului"
              value={values.vendorLastName}
              onChange={(ev) => handleChange("vendorLastName", ev.target.value)}
              onBlur={() => handleBlur("vendorLastName", true)}
              error={errorHandling.isInvalid("vendorLastName")}
              required
              disabled={loading || vendorPreset !== VendorPresetEnum.Other}
              sx={styles.input}
            />
          </Stack>
          <Stack component="fieldset" sx={styles.fieldset}>
            <Box component="legend">Vehicle</Box>

            <Autocomplete
              freeSolo
              options={Object.values(VehicleTypeEnum)}
              value={values.type}
              onChange={(ev, value) => handleChange("type", value)}
              disabled={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tip"
                  error={errorHandling.isInvalid("type")}
                  onChange={(ev) => handleChange("type", ev.target.value)}
                  onBlur={() => handleBlur("type", true)}
                  required
                />
              )}
              sx={styles.input}
            />

            <TextField
              label="VIN"
              value={values.vin}
              onChange={(ev) => handleChange("vin", ev.target.value)}
              onBlur={() => handleBlur("vin", true)}
              error={errorHandling.isInvalid("vin")}
              required
              disabled={loading}
              sx={styles.input}
            />

            <Autocomplete
              freeSolo
              options={brands.map((i) => i.name)}
              value={values.brand}
              onChange={(ev, value) => handleChange("brand", value)}
              loading={brandsIsLoading}
              disabled={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Marca"
                  error={errorHandling.isInvalid("brand")}
                  onChange={(ev) => handleChange("brand", ev.target.value)}
                  onBlur={() => handleBlur("brand", true)}
                  required
                />
              )}
              sx={styles.input}
            />

            <Autocomplete
              freeSolo
              options={models.map((i) => i.name)}
              value={values.model}
              onChange={(ev, value) => handleChange("model", value)}
              loading={modelsIsLoading}
              disabled={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Model"
                  error={errorHandling.isInvalid("model")}
                  onChange={(ev) => handleChange("model", ev.target.value)}
                  onBlur={() => handleBlur("model", true)}
                  required
                />
              )}
              sx={styles.input}
            />

            <TextField
              label="Anul Producției"
              value={values.yearOfProduction}
              onChange={(ev) =>
                handleChange("yearOfProduction", ev.target.value)
              }
              onBlur={() => handleBlur("yearOfProduction", true)}
              error={errorHandling.isInvalid("yearOfProduction")}
              required
              type="number"
              inputProps={{ step: "1" }}
              disabled={loading}
              sx={styles.input}
            />
            <TextField
              label="Culoare"
              value={values.color}
              onChange={(ev) => handleChange("color", ev.target.value)}
              onBlur={() => handleBlur("color", true)}
              error={errorHandling.isInvalid("color")}
              required
              disabled={loading}
              sx={styles.input}
            />
            <TextField
              label="Numărul declarației"
              value={values.declarationNumber}
              onChange={(ev) =>
                handleChange("declarationNumber", ev.target.value)
              }
              onBlur={() => handleBlur("declarationNumber", true)}
              error={errorHandling.isInvalid("declarationNumber")}
              required
              disabled={loading}
              sx={styles.input}
            />
            <TextField
              label="Preț în cifre"
              value={values.priceNumber}
              onChange={(ev) => handleChange("priceNumber", ev.target.value)}
              onBlur={() => handleBlur("priceNumber", true)}
              error={errorHandling.isInvalid("priceNumber")}
              required
              type="number"
              inputProps={{ step: "1" }}
              disabled={loading}
              sx={styles.input}
            />
            <TextField
              label="Preț în cuvinte"
              value={values.priceWord}
              onChange={(ev) => handleChange("priceWord", ev.target.value)}
              onBlur={() => handleBlur("priceWord", true)}
              error={errorHandling.isInvalid("priceWord")}
              required
              disabled={loading}
              sx={styles.input}
            />
          </Stack>

          {values.clientType === ClientTypeEnum.Individual && (
            <Stack component="fieldset" sx={styles.fieldset}>
              <Box component="legend">Client individual</Box>
              {genericClientFields}
              <TextField
                label="Prenume proprietarului"
                value={values.ownerFirstName}
                onChange={(ev) =>
                  handleChange("ownerFirstName", ev.target.value)
                }
                onBlur={() => handleBlur("ownerFirstName", true)}
                error={errorHandling.isInvalid("ownerFirstName")}
                required
                disabled={loading}
                sx={styles.input}
              />
              <TextField
                label="Numele proprietarului"
                value={values.ownerLastName}
                onChange={(ev) =>
                  handleChange("ownerLastName", ev.target.value)
                }
                onBlur={() => handleBlur("ownerLastName", true)}
                error={errorHandling.isInvalid("ownerLastName")}
                required
                disabled={loading}
                sx={styles.input}
              />
              <TextField
                label="IDNP proprietarului"
                value={values.ownerIdnp}
                onChange={(ev) => handleChange("ownerIdnp", ev.target.value)}
                onBlur={() => handleBlur("ownerIdnp", true)}
                error={errorHandling.isInvalid("ownerIdnp")}
                required
                disabled={loading}
                sx={styles.input}
              />
              <TextField
                label="Numărul pașaportului proprietarului"
                value={values.ownerPassportNumber}
                onChange={(ev) =>
                  handleChange("ownerPassportNumber", ev.target.value)
                }
                onBlur={() => handleBlur("ownerPassportNumber", true)}
                error={errorHandling.isInvalid("ownerPassportNumber")}
                required
                disabled={loading}
                sx={styles.input}
              />
              <TextField
                label="Data pașaportului proprietarului"
                value={values.ownerPassportDate}
                onChange={(ev) =>
                  handleChange("ownerPassportDate", ev.target.value)
                }
                onBlur={() => handleBlur("ownerPassportDate", true)}
                error={errorHandling.isInvalid("ownerPassportDate")}
                required
                disabled={loading}
                sx={styles.input}
              />
              <TextField
                label="Adresa proprietarului"
                value={values.ownerAddress}
                onChange={(ev) => handleChange("ownerAddress", ev.target.value)}
                onBlur={() => handleBlur("ownerAddress", true)}
                error={errorHandling.isInvalid("ownerAddress")}
                required
                disabled={loading}
                sx={styles.input}
              />
            </Stack>
          )}
          {values.clientType === ClientTypeEnum.LegalEntity && (
            <Stack component="fieldset" sx={styles.fieldset}>
              <Box component="legend">Companie</Box>
              {genericClientFields}
              <TextField
                label="Numele companiei"
                value={values.companyName}
                onChange={(ev) => handleChange("companyName", ev.target.value)}
                onBlur={() => handleBlur("companyName", true)}
                error={errorHandling.isInvalid("companyName")}
                required
                disabled={loading}
                sx={styles.input}
              />
              <TextField
                label="Codul fiscal al companiei"
                value={values.companyFiscalCode}
                onChange={(ev) =>
                  handleChange("companyFiscalCode", ev.target.value)
                }
                onBlur={() => handleBlur("companyFiscalCode", true)}
                error={errorHandling.isInvalid("companyFiscalCode")}
                required
                disabled={loading}
                sx={styles.input}
              />
              <TextField
                label="adresa companiei"
                value={values.companyAddress}
                onChange={(ev) =>
                  handleChange("companyAddress", ev.target.value)
                }
                onBlur={() => handleBlur("companyAddress", true)}
                error={errorHandling.isInvalid("companyAddress")}
                required
                disabled={loading}
                sx={styles.input}
              />
              <TextField
                label="Compania Iban"
                value={values.companyIban}
                onChange={(ev) => handleChange("companyIban", ev.target.value)}
                onBlur={() => handleBlur("companyIban", true)}
                error={errorHandling.isInvalid("companyIban")}
                required
                disabled={loading}
                sx={styles.input}
              />
              <TextField
                label="Numele băncii companiei"
                value={values.companyBankName}
                onChange={(ev) =>
                  handleChange("companyBankName", ev.target.value)
                }
                onBlur={() => handleBlur("companyBankName", true)}
                error={errorHandling.isInvalid("companyBankName")}
                required
                disabled={loading}
                sx={styles.input}
              />
              <TextField
                label="Cod bancar al companiei"
                value={values.companyBankCode}
                onChange={(ev) =>
                  handleChange("companyBankCode", ev.target.value)
                }
                onBlur={() => handleBlur("companyBankCode", true)}
                error={errorHandling.isInvalid("companyBankCode")}
                required
                disabled={loading}
                sx={styles.input}
              />
              <TextField
                label="Administratorul companiei"
                value={values.companyAdministrator}
                onChange={(ev) =>
                  handleChange("companyAdministrator", ev.target.value)
                }
                onBlur={() => handleBlur("companyAdministrator", true)}
                error={errorHandling.isInvalid("companyAdministrator")}
                required
                disabled={loading}
                sx={styles.input}
              />
            </Stack>
          )}

          <Stack component="fieldset" sx={styles.fieldset}>
            <Box component="legend">Fisiere</Box>
            <DriveFilesSelector
              genericFiles={[
                ...(values.clientType === ClientTypeEnum.Individual
                  ? DRIVE_FILES_INDIVIDUAL
                  : values.clientType === ClientTypeEnum.LegalEntity
                    ? DRIVE_FILES_LEGAL_ENTITY
                    : []),
                ...DRIVE_FILES_SERVICE_SCHEDULE,
                ...OTHER_DRIVE_FILES_GENERIC,
              ]}
              selectedFiles={selectedFiles}
              onSelectedFilesChange={(files) => setSelectedFiles(files)}
              onCreateServiceScheduleTemplate={(model) =>
                setModelToCreateServiceSchedule(model)
              }
            />
          </Stack>

          <Stack gap={1} direction="row" sx={styles.buttonsWrapper}>
            {withTest && (
              <Button onClick={handleFillTestData}>Fill test data</Button>
            )}
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isValid}
              loading={loading}
              loadingPosition="start"
              startIcon={<AutoFixHighSharpIcon />}
              sx={styles.submit}
            >
              {loading ? "Așteptați…" : "Trimite"}
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>

      {!!modelToCreateServiceSchedule && (
        <ServiceScheduleDialog
          open
          sourceFile={DRIVE_FILES_SERVICE_SCHEDULE[0]}
          model={modelToCreateServiceSchedule}
          onClose={(generatedFile) =>
            handleServiceScheduleGenerated(generatedFile)
          }
        />
      )}

      {generatedDocument && (
        <Dialog open onClose={handleCloseDialog}>
          <DialogTitle>Actele au fost completate</DialogTitle>
          <DialogContent>
            <Typography color="textSecondary" gutterBottom>
              Deschideți PDF-ul generat cu toate necesare fișiere și tipăriți-l
            </Typography>
            <Button
              variant="outlined"
              href={generatedDocument.url}
              target="_blank"
              endIcon={<OpenInNewSharpIcon />}
              autoFocus
              sx={styles.documentLink}
            >
              {generatedDocument.name}.pdf
            </Button>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseDialog}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ContractForm;

const styles = mapStyles({
  root: {},
  form: {
    width: "100%",
  },
  fieldset: {
    gap: 1.5,
    my: 1,
    p: 1,
    border: (ct) => `1px solid ${ct.palette.divider}`,
    borderRadius: 1,
  },
  input: {},
  buttonsWrapper: {
    // position: "sticky",
    // bottom: 0,
    background: (ct) => ct.palette.background.default,
    pt: 2,
    px: 2,
    mx: -2,
    borderTop: (ct) => `1px solid ${ct.palette.divider}`,
    zIndex: 1,
  },
  submit: {
    flexGrow: 1,
    minWidth: "min(25%, 100px)",
    "&.MuiLoadingButton-loading .MuiButton-icon": {
      display: "none",
    },
    "& .MuiLoadingButton-loadingIndicator": {
      position: "static",
      marginRight: 1,
      marginLeft: -0.5,
    },
  },
  documentLink: {
    lineHeight: "1em",
    minHeight: "36px",
    justifyContent: "space-between",
    width: "100%",
  },
});
