import { deepMerge } from "./object";

export const mergeStyles = (...styles: Nullable<Style>[]): Style => {
  return styles.reduce((acc: Style, item: Nullable<Style>) => {
    return (item ? deepMerge(acc, item) : acc) as Style;
  }, {} as Style);
};

export const ifStyles = (condition: Nullable<boolean>, style: Style = {}, fallback: Style = {}): Style => {
  return condition ? style : fallback;
};

export function mapStyles<PropKey extends string = string>(styles: Record<PropKey, Style>): ClassNameMap<PropKey> {
  return styles;
}
