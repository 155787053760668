import { DateTime } from "luxon";
import { DATE_FORMAT } from "../constants/formDefaultValues";

export const dateTimeAdapter = (
  value: Nullable<DateString>,
): DateTime | null => {
  if (!value) return null;
  const dt = DateTime.fromFormat(value, DATE_FORMAT, { zone: "utc" });
  return dt.isValid ? dt : null;
};

export const outputDate = (date: DateTime): string => {
  return `${date.toFormat(DATE_FORMAT)}`;
};
