export enum DocumentTypeEnum {
  ActPremirePredareFizica = "1DpZRo_3FJVCaJIPr-LSqotwsSWG0HmS9EOV2OBB0WmI",
  ActPremirePredareJuridica = "1BEGJaFXpboTL0PvdrsbyTgm410Flb5x-SO3F3akA7Yk",
  ContractMotopartsFizica = "1-xCEvSkGPArWxh_gdomtjt2MRR2G_k-6yuyokzKl6q8",
  ContractMotopartsJuridica = "1T6y7pOygLXczchTlQEPdE4sOBBQEKN9X5ISGsT0y3HM",
  FisaTehnicaAtv = "1Jd4Zc7g_IFeUdJ7i9Jxw7Flr0dqJoDoUnOswkk0J_B4",
  TalonDeGarantieAtv = "1V-qKjwZ4JHrhqu9n_s9raf7mlcY0Cys7hJ-y4jBPWZc",
  ConditiiSiInstructiuniAtv = "1vF32J7G1uE5TBDK-Z0EQB7EZVTLGJAZW8mpYQ8N9isI",
  ServicePlanTgb = "1mpy_01ohoPo91Kw5nE0PpT4hTVV1a-Km08SJzKzYgas",
}

export enum ClientTypeEnum {
  Individual = "Individual",
  LegalEntity = "Companie",
}

export enum VendorPresetEnum {
  SmocvinAlexandru = "Smocvin Alexandru",
  SlastnicovIgor = "Slastnicov Igor",
  Other = "Alt vânzător",
}

export enum BrandsEnum {
  BMW = "BMW",
  BRP = "BRP",
  CFMoto = "CFMoto",
  Ducati = "Ducati",
  Honda = "Honda",
  Husqvarna = "Husqvarna",
  Indian = "Indian",
  Kawasaki = "Kawasaki",
  KTM = "KTM",
  Piaggio = "Piaggio",
  Polaris = "Polaris",
  Suzuki = "Suzuki",
  Segway = "Segway",
  Triumph = "Triumph",
  Yamaha = "Yamaha",
  TGB = "TGB",
  Linhai = "Linhai",
}

export enum VehicleTypeEnum {
  Moto = "Motocicleta",
  ATV = "Cvadriciclu",
}
