import React, { ReactElement } from "react";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from "react-sortable-hoc";
import { List, ListItemButton, Typography } from "@mui/material";
import { mapStyles, mergeStyles } from "../libs/styles";

interface SortableListItemProps extends SortableElementProps {
  label?: string;
  listItem?: ReactElement;
  idx?: number;
}

const SortableListItem = SortableElement<SortableListItemProps>(
  (props: SortableListItemProps) => {
    const { listItem, label } = props;
    return (
      listItem || <ListItemButton sx={styles.listItem}>{label}</ListItemButton>
    );
  },
);

interface SortableListProps extends SortableContainerProps {
  items: any[];
  label?: (value: any, index: number) => string;
  listItem?: (value: any, index: number) => ReactElement;
  sx?: Style;
  component?: React.ElementType;
}

const SortableList = SortableContainer<SortableListProps>(
  ({ items, label, listItem, sx, component }: SortableListProps) => (
    <List sx={mergeStyles(styles.list, sx)} component={component ?? "ul"}>
      {!items.length && <Typography sx={styles.noItems}>No items</Typography>}
      {items.map((value, index) => (
        <SortableListItem
          key={index}
          index={index}
          idx={index}
          label={label && label(value, index)}
          listItem={listItem && listItem(value, index)}
        />
      ))}
    </List>
  ),
);

export default SortableList;

const styles = mapStyles({
  list: {
    overflow: "hidden",
  },
  listItem: {
    cursor: "grab",
    backgroundColor: "common.white",
  },
  noItems: {
    height: 40,
    py: 0,
    px: 2,
    color: "grey",
    display: "flex",
    alignItems: "center",
  },
});
