import * as Yup from "yup";
import { ClientTypeEnum } from "../typings/enums";

const requiredMessage = "Acest câmp este obligatoriu";
export const validationSchema = Yup.object({
  date: Yup.string().required(requiredMessage),
  contractNumber: Yup.string().required(requiredMessage),
  clientType: Yup.string().required(requiredMessage),

  phone: Yup.string().required(requiredMessage),
  email: Yup.string().email().required(requiredMessage),

  ownerFirstName: Yup.string().when("clientType", ([clientType], schema) => {
    return clientType === ClientTypeEnum.Individual
      ? schema.required(requiredMessage)
      : schema;
  }),
  ownerLastName: Yup.string().when("clientType", ([clientType], schema) => {
    return clientType === ClientTypeEnum.Individual
      ? schema.required(requiredMessage)
      : schema;
  }),
  ownerIdnp: Yup.string().when("clientType", ([clientType], schema) => {
    return clientType === ClientTypeEnum.Individual
      ? schema.required(requiredMessage)
      : schema;
  }),
  ownerPassportNumber: Yup.string().when(
    "clientType",
    ([clientType], schema) => {
      return clientType === ClientTypeEnum.Individual
        ? schema.required(requiredMessage)
        : schema;
    },
  ),
  ownerPassportDate: Yup.string().when("clientType", ([clientType], schema) => {
    return clientType === ClientTypeEnum.Individual
      ? schema.required(requiredMessage)
      : schema;
  }),
  ownerAddress: Yup.string().when("clientType", ([clientType], schema) => {
    return clientType === ClientTypeEnum.Individual
      ? schema.required(requiredMessage)
      : schema;
  }),

  companyName: Yup.string().when("clientType", ([clientType], schema) => {
    return clientType === ClientTypeEnum.LegalEntity
      ? schema.required(requiredMessage)
      : schema;
  }),
  companyFiscalCode: Yup.string().when("clientType", ([clientType], schema) => {
    return clientType === ClientTypeEnum.LegalEntity
      ? schema.required(requiredMessage)
      : schema;
  }),
  companyAddress: Yup.string().when("clientType", ([clientType], schema) => {
    return clientType === ClientTypeEnum.LegalEntity
      ? schema.required(requiredMessage)
      : schema;
  }),
  companyIban: Yup.string().when("clientType", ([clientType], schema) => {
    return clientType === ClientTypeEnum.LegalEntity
      ? schema.required(requiredMessage)
      : schema;
  }),
  companyBankName: Yup.string().when("clientType", ([clientType], schema) => {
    return clientType === ClientTypeEnum.LegalEntity
      ? schema.required(requiredMessage)
      : schema;
  }),
  companyBankCode: Yup.string().when("clientType", ([clientType], schema) => {
    return clientType === ClientTypeEnum.LegalEntity
      ? schema.required(requiredMessage)
      : schema;
  }),
  companyAdministrator: Yup.string().when(
    "clientType",
    ([clientType], schema) => {
      return clientType === ClientTypeEnum.LegalEntity
        ? schema.required(requiredMessage)
        : schema;
    },
  ),

  vendorFirstName: Yup.string().required(requiredMessage),
  vendorLastName: Yup.string().required(requiredMessage),

  type: Yup.string().required(requiredMessage),
  vin: Yup.string().required(requiredMessage),
  brand: Yup.string().required(requiredMessage),
  model: Yup.string().required(requiredMessage),
  yearOfProduction: Yup.number().required(requiredMessage),
  color: Yup.string().required(requiredMessage),
  declarationNumber: Yup.string().required(requiredMessage),

  priceNumber: Yup.number().required(requiredMessage),
  priceWord: Yup.string().required(requiredMessage),
});
