import { DriveFile } from "../typings/models";

export const DRIVE_FILES_INDIVIDUAL: DriveFile[] = [
  {
    name: "ActPremirePredareFizica",
    id: "1DpZRo_3FJVCaJIPr-LSqotwsSWG0HmS9EOV2OBB0WmI",
  },
  {
    name: "ContractMotopartsFizica",
    id: "1-xCEvSkGPArWxh_gdomtjt2MRR2G_k-6yuyokzKl6q8",
  },
];

export const DRIVE_FILES_LEGAL_ENTITY: DriveFile[] = [
  {
    name: "Act Premire Predare Juridica",
    id: "1BEGJaFXpboTL0PvdrsbyTgm410Flb5x-SO3F3akA7Yk",
  },
  {
    name: "Contract Motoparts Juridica",
    id: "1T6y7pOygLXczchTlQEPdE4sOBBQEKN9X5ISGsT0y3HM",
  },
];

export const DRIVE_FILES_SERVICE_SCHEDULE: DriveFile[] = [
  {
    name: "Service Schedule Template",
    id: "1Jd4Zc7g_IFeUdJ7i9Jxw7Flr0dqJoDoUnOswkk0J_B4",
  },
];
export const OTHER_DRIVE_FILES_GENERIC: DriveFile[] = [
  {
    name: "Talon De Garantie Atv",
    id: "1V-qKjwZ4JHrhqu9n_s9raf7mlcY0Cys7hJ-y4jBPWZc",
  },
  {
    name: "Conditii Si Instructiuni Atv",
    id: "1vF32J7G1uE5TBDK-Z0EQB7EZVTLGJAZW8mpYQ8N9isI",
  },
  {
    name: "ServicePlanTgb",
    id: "1mpy_01ohoPo91Kw5nE0PpT4hTVV1a-Km08SJzKzYgas",
  },
];
