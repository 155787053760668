import React from "react";
import { Stack, Typography } from "@mui/material";
import { mapStyles } from "../libs/styles";
import { MotoPartsIcon } from "../components/icons/MotoPartsIcon";
import ContractForm from "../components/ContractForm";

export const MainPage: React.FC = (props) => {
  return (
    <Stack sx={styles.root}>
      <MotoPartsIcon sx={styles.logo} />
      <Typography variant="h1" sx={styles.heading}>
        Contract generator
      </Typography>
      <ContractForm />
    </Stack>
  );
};

const styles = mapStyles({
  root: {
    gap: 2,
    p: 2,
    margin: "0 auto",
    width: "100%",
    alignItems: "center",
  },
  logo: {
    fontSize: "200px",
    height: "auto",
    mt: 4,
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    mt: 2,
  },
});

export default MainPage;
