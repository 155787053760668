import { useEffect, useMemo } from 'react';

function useSignal(): AbortSignal {
  const ctrl = useMemo(() => new AbortController(), []);

  useEffect(() => {
    return () => ctrl.abort();
  }, [ctrl]);

  return ctrl.signal;
}

export default useSignal;
