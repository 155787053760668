import {
  ApiResponse,
  ApiResponseError,
  ApiResponseSuccess,
} from "../typings/interfaces";
import { API_URL } from "../constants/common";

const defaults: RequestInit = {
  method: "GET",
  headers: {
    // Accept: "application/json",
    // "Content-Type": "application/json",
  },
  redirect: "follow",
};

export async function decorateInit(
  init: RequestInit = {},
): Promise<RequestInit> {
  return {
    ...defaults,
    ...init,
    headers: {
      ...defaults.headers,
      ...init.headers,
    },
  };
}

async function fetcher<T = any>(
  url: string,
  init?: RequestInit,
): Promise<ApiResponse<T>> {
  try {
    const requestInit = await decorateInit(init);
    if (Array.isArray(url)) url = url[0];

    const urlPrefix =
      url.startsWith("https://") || url.startsWith("http://") ? "" : API_URL;

    const response = await fetch(urlPrefix + url, requestInit);

    if (response.status === 401) {
      console.error("[fetcher]", await response.text());
      return response.json();
    }
    if (response.status === 403) {
      console.error("[fetcher]", await response.text());
      return response.json();
    }
    if (response.status >= 200 && response.status < 400) {
      if (response.status === 204) {
        return response.json(); // null as any as T satisfies ApiResponseSuccess<T>;
      }

      if (response.headers.get("Content-Type")?.includes("application/json")) {
        return (await response.json()) satisfies ApiResponseSuccess<T>;
      } else {
        const text = await response.text();
        return { data: text as T, status: "success" } satisfies ApiResponse<T>;
      }
    }

    const result = (await response.json()) as ApiResponseError;

    console.error("[fetcher]", JSON.stringify(result));
    return result;
  } catch (error) {
    console.error(`[fetcher]`, error);
    return {
      data: null,
      message: "Generic Error",
      status: "error",
    } satisfies ApiResponse<T>;
  }
}

export default fetcher;
