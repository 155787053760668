import { ContractFormModel } from "../typings/models";
import { DateTime } from "luxon";
import { ClientTypeEnum, VehicleTypeEnum } from "../typings/enums";

export const DATE_FORMAT = "dd.MM.yyyy";
export const CONTRACT_NUMBER_FORMAT = "yyMMddHHmm";
export const FORM_DEFAULT_VALUES: ContractFormModel = {
  date: DateTime.now().toFormat(DATE_FORMAT),
  contractNumber: DateTime.now().toFormat(CONTRACT_NUMBER_FORMAT),
  clientType: ClientTypeEnum.Individual,

  phone: "",
  email: "",

  ownerFirstName: "",
  ownerLastName: "",
  ownerIdnp: "",
  ownerPassportNumber: "",
  ownerPassportDate: "",
  ownerAddress: "",

  companyName: "",
  companyFiscalCode: "",
  companyAddress: "",
  companyIban: "",
  companyBankName: "",
  companyBankCode: "",
  companyAdministrator: "",

  vendorFirstName: "",
  vendorLastName: "",

  type: "",
  vin: "",
  brand: "",
  model: "",
  yearOfProduction: DateTime.now().toFormat("yyyy"),
  color: "",
  declarationNumber: "",

  priceNumber: "0",
  priceWord: "",

  service1: "",
  service2: "",
  service3: "",
  service4: "",
  service5: "",
  service6: "",
  service7: "",
  service8: "",
  service9: "",
  service10: "",
};

export const FORM_TEST_VALUES: ContractFormModel = {
  date: DateTime.now().toFormat(DATE_FORMAT),
  contractNumber: "12345",
  clientType: ClientTypeEnum.Individual, // 'legalEntity' or 'individual'
  phone: "+37312345678",
  email: "vasea.pupkin@example.com",

  ownerFirstName: "Vasea",
  ownerLastName: "Pupkin",
  ownerIdnp: "2001123456789",
  ownerPassportNumber: "B123456789",
  ownerPassportDate: "12.08.1999",
  ownerAddress: "Hincesti, Valea Florii",
  vendorFirstName: "John",
  vendorLastName: "Doe",

  companyName: '"Рога и копыта" SRL',
  companyFiscalCode: "100020003000",
  companyAddress: "mun.Chisinau, str.Studentilor 2/4 of 202",
  companyIban: "MD68ML000000002251609858",
  companyBankName: "BC MOLDINDCONBANC SA",
  companyBankCode: "MOLDMD2X309",
  companyAdministrator: "Alex Puskin",

  vin: "1234567890123456",
  brand: "Best Brand",
  model: "Super Model 3000",
  type: VehicleTypeEnum.ATV,
  yearOfProduction: "2023",
  color: "Combinate",
  declarationNumber: "24MD207000R4011086",
  priceNumber: "85125",
  priceWord: "optzeci și cinci de mii o sută douăzeci și cinci",

  service1: "1000",
  service2: "5000",
  service3: "10000",
  service4: "15000",
  service5: "20000",
  service6: "25000",
  service7: "30000",
  service8: "35000",
  service9: "40000",
  service10: "45000",
};
